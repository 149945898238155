import * as Schema from '~/schema';

import request, { DELETE, GET, PATCH, POST, PUT } from './request';

const organisationUrl = '/organisations{/organisation_id}';

/* eslint-disable key-spacing, no-multi-spaces */
export const auth = {
  login: request(POST, '/sign_in'),
  logout: request(DELETE, '/sign_out'),
  register: request(POST, '/sign_up'),
  confirm: request(GET, '/users/confirmation{?confirmation_token}'),
  acceptInvite: request(GET, '/users/invitation/accept{?invitation_token}'),
  finishInvite: request(PUT, '/users/invitation'),
  forgotPassword: request(POST, '/forgot_password'),
  resetPassword: request(PUT, '/reset_password'),
  openIdRedirect: request(POST, '/users/auth{/provider}'),
  openId: request(POST, '/users/auth{/provider}/callback{?code,scope,state}'),
};

export const resources = {
  timezones: request(GET, '/references/timezones'),
};

export const me = {
  find: request(GET, '/me'),
  update: request(PUT, '/me'),
  addAccess: request(POST, '/me/add_access'),
};

export const userSettings = {
  find: request(GET, '/me/settings'),
  update: request(PATCH, '/me/settings'),
};

export const accessTokens = {
  list: request(GET, '/access_tokens', {
    accessTokens: Schema.accessTokens,
  }),
  create: request(POST, '/access_tokens', {
    accessToken: Schema.accessToken,
  }),
  delete: request(DELETE, '/access_tokens{/id}'),
};

export const organisations = {
  list: request(GET, '/organisations', { organisations: Schema.organisations }),
  find: request(GET, '/organisations{/id}', {
    organisation: Schema.organisation,
  }),
  create: request(POST, '/organisations', {
    organisation: Schema.organisation,
  }),
  update: request(PUT, '/organisations{/id}', {
    organisation: Schema.organisation,
  }),
  delete: request(DELETE, '/organisations{/id}'),
  updateSettings: request(PUT, '/organisations{/id}/settings', {
    organisation: Schema.organisation,
  }),
  pause: request(PUT, '/organisations{/id}/auto/pause', {
    organisation: Schema.organisation,
  }),
  unpause: request(PUT, '/organisations{/id}/auto/unpause', {
    organisation: Schema.organisation,
  }),
};

export const imports = {
  last: request(GET, `${organisationUrl}/imports/last`, {
    import: Schema.lastImport,
  }),
  create: request(GET, `${organisationUrl}/imports/new`),
};

export const users = {
  list: request(GET, `${organisationUrl}/users`, { users: Schema.users }),
  find: request(GET, `${organisationUrl}/users{/id}`, { user: Schema.user }),
  create: request(POST, `${organisationUrl}/users`, { users: Schema.users }),
  update: request(PUT, `${organisationUrl}/users{/id}`, { user: Schema.user }),
  delete: request(DELETE, `${organisationUrl}/users{/id}`),
  invite: request(POST, '/users/invitation', { user: Schema.user }),
  partner: request(POST, '/users/invitation/partner', { user: Schema.user }),
};
// Alias for revoke because one cannot just delete another user
users.revoke = users.delete;

export const roles = {
  create: request(POST, `${organisationUrl}/user_roles`),
  delete: request(DELETE, `${organisationUrl}/user_roles{/role_name}`),
};

export const reports = {
  find: request(GET, `${organisationUrl}/reports{/id}{?params*}`, {
    report: Schema.report,
  }),
  call: request(GET, `${organisationUrl}/reports/agent_activity{?params*}`, {
    report: Schema.report,
  }),
  dashboard: request(GET, `${organisationUrl}/reports/dashboard{?params*}`, {
    report: Schema.report,
  }),
  email: request(
    POST,
    `${organisationUrl}/reports/agent_activity/email{?params*}`
  ),
};

export const jobs = {
  find: request(GET, `/jobs{/id}`, {
    job: Schema.job,
  }),
};

const baseCollectionsUrl = '/organisations{/organisation_id}/collections';
export const collections = {
  list: request(GET, `${baseCollectionsUrl}{?params*}`, {
    collections: Schema.collections,
  }),
  schedule: request(GET, `${baseCollectionsUrl}/schedule{?params*}`, {
    collections: Schema.collections,
  }),
  find: request(GET, `${baseCollectionsUrl}{/id}`, {
    collection: Schema.collection,
  }),
  actionFind: request(GET, `${baseCollectionsUrl}{/id}`),
  approve: request(PUT, `${baseCollectionsUrl}/approve`, {
    collections: Schema.collections,
  }),
  moveTo: request(PUT, `${baseCollectionsUrl}/move_to`, {
    collections: Schema.collections,
  }),
  cancel: request(PUT, `${baseCollectionsUrl}/cancel`, {
    collections: Schema.collections,
  }),
  pause: request(PUT, `${baseCollectionsUrl}/pause`, {
    collections: Schema.collections,
  }),
  unpause: request(PUT, `${baseCollectionsUrl}/unpause`, {
    collections: Schema.collections,
  }),
  resume: request(PUT, `${baseCollectionsUrl}/resume`, {
    collections: Schema.collections,
  }),
  reassign: request(PUT, `${baseCollectionsUrl}/assign`, {
    collections: Schema.collections,
  }),
  bulkAssign: request(PUT, `${baseCollectionsUrl}/bulk_assign`, {
    job: Schema.job,
  }),
  skipToNextStep: request(PUT, `${baseCollectionsUrl}/skip_to_next_reminder`, {
    collection: Schema.collection,
  }),
  bulkSkipToNextStep: request(
    PUT,
    `${baseCollectionsUrl}/skip_to_next_reminder`,
    {
      job: Schema.job,
    }
  ),
  updateAll: request(PUT, `${baseCollectionsUrl}/update_all`, {
    collections: Schema.collections,
  }),
  agentEvent: request(PUT, `${baseCollectionsUrl}/agent_event`, {
    collections: Schema.collections,
  }),
  stats: request(GET, `${baseCollectionsUrl}/stats{?params*}`),
};

export const collectors = {
  list: request(GET, `${organisationUrl}/collectors`, {
    collectors: Schema.collectors,
  }),
  find: request(GET, `${organisationUrl}/collectors{/id}`, {
    collector: Schema.collector,
  }),
  add: request(POST, `${organisationUrl}/collectors/new`),
  create: request(POST, `${organisationUrl}/collectors`, {
    collector: Schema.collector,
  }),
  update: request(PUT, `${organisationUrl}/collectors{/id}`, {
    collector: Schema.collector,
  }),
  delete: request(DELETE, `${organisationUrl}/collectors{/id}`, {
    collector: Schema.collector,
  }),
  clone: request(PUT, `${organisationUrl}/collectors{/id}/clone`, {
    collector: Schema.collector,
  }),
};

export const reminders = {
  list: request(GET, `${organisationUrl}/reminders`, {
    reminders: Schema.reminders,
  }),
  find: request(GET, `${organisationUrl}/reminders{/id}`, {
    reminder: Schema.reminder,
  }),
  add: request(POST, `${organisationUrl}/reminders/new`, {
    reminder: Schema.reminder,
  }),
  create: request(POST, `${organisationUrl}/reminders`, {
    reminder: Schema.reminder,
  }),
  update: request(PUT, `${organisationUrl}/reminders{/id}`, {
    reminder: Schema.reminder,
  }),
  delete: request(DELETE, `${organisationUrl}/reminders{/id}`, {
    reminder: Schema.reminder,
  }),
  sample: request(POST, `${organisationUrl}/reminders{/id}/sample`),
};

export const contacts = {
  find: request(GET, `${organisationUrl}/contacts{/id}{?include_collections}`, {
    contact: Schema.contact,
  }),
  actionFind: request(GET, `${organisationUrl}/contacts{/id}`),
  list: request(GET, `${organisationUrl}/contacts{?params*}`, {
    contacts: Schema.contacts,
  }),
  search: request(POST, `${organisationUrl}/contacts/search`, {
    contacts: Schema.contacts,
  }),
  update: request(PUT, `${organisationUrl}/contacts{/id}`, {
    contact: Schema.contact,
  }),
  reassign: request(PUT, `${organisationUrl}/contacts/reassign`, {
    contacts: Schema.contacts,
  }),
  bulkReassign: request(PUT, `${organisationUrl}/contacts/bulk_reassign`, {
    job: Schema.job,
  }),
  moveTo: request(PUT, `${organisationUrl}/contacts/move_to`, {
    contacts: Schema.contacts,
  }),
  sample: request(GET, `${organisationUrl}/contacts/sample`, {
    contact: Schema.contact,
  }),
};

const activityParams = [
  'page',
  'after_id',
  'before_id',
  'from',
  'to',
  'activity_key',
  'owner_id',
  'trackable[type]',
];
export const activities = {
  list: request(
    GET,
    `${organisationUrl}/activities{/resource_type}{/resource_id}{?${activityParams.toString()}}`,
    {
      activities: Schema.activities,
    }
  ),
  contact: request(
    GET,
    `${organisationUrl}/activities/contact{/id}{?page,after_id,before_id}`,
    {
      activities: Schema.activities,
    }
  ),
};

export const oauth = {
  begin: request(GET, '/provider{/provider}/new{?return_url,organisation_id}'),
  finish: request(POST, '/provider{/provider}'),
  reauth: request(PUT, '/provider{/provider}'),
  disconnect: request(DELETE, '/provider{/provider}{?organisation_id}'),
};

export const gettingStarted = {
  sampleReminder: request(
    POST,
    '/organisations{/organisation_id}/collections/approve_sample'
  ),
};

export const publicInvoice = {
  find: request(GET, '/public/invoices{/hash}'),
  pdf: request(GET, '/public/invoices{/hash}.pdf'),
};

export const publicContact = {
  find: request(GET, '/public/contacts{/hash}'),
};

export const invoices = {
  resend: request(PUT, '/organisations{/organisation_id}/invoices/resend'),
  update: request(PUT, '/organisations{/organisation_id}/invoices{/id}'),
  updateAll: request(
    PUT,
    '/organisations{/organisation_id}/invoices/update_all',
    { invoices: [Schema.invoice] }
  ),
};

export const notes = {
  list: request(GET, `${organisationUrl}/notes{?params*}`, {
    notes: [Schema.note],
  }),
  create: request(POST, `${organisationUrl}/notes`, {
    note: Schema.note,
  }),
  update: request(PUT, `${organisationUrl}/notes{/id}`, {
    note: Schema.note,
  }),
  delete: request(DELETE, `${organisationUrl}/notes{/id}`, {
    note: Schema.note,
  }),
  resolve: request(POST, `${organisationUrl}/notes{/id}/resolve`, {
    note: Schema.note,
  }),
  resolveOnly: request(PATCH, `${organisationUrl}/notes{/id}/resolve`, {
    note: Schema.note,
  }),
};

export const phoneCalls = {
  new: request(GET, `${organisationUrl}/phone_calls/new`),
  create: request(POST, `${organisationUrl}/phone_calls`, {
    phoneCall: Schema.phoneCall,
  }),
};

export const subscription = {
  find: request(GET, `${organisationUrl}/subscription`, {
    subscription: Schema.subscription,
  }),
  update: request(PUT, `${organisationUrl}/subscription`, {
    subscription: Schema.subscription,
  }),
  coupons: request(POST, `${organisationUrl}/subscription/coupons`, {
    subscription: Schema.subscription,
  }),
};

export const listFilters = {
  list: request(GET, `${organisationUrl}/list_filters`, {
    listFilters: [Schema.listFilter],
  }),
  create: request(POST, `${organisationUrl}/list_filters`, {
    listFilter: Schema.listFilter,
  }),
  update: request(PUT, `${organisationUrl}/list_filters{/id}`, {
    listFilter: Schema.listFilter,
  }),
  delete: request(DELETE, `${organisationUrl}/list_filters{/id}`, {
    listFilter: Schema.listFilter,
  }),
};

export const integrations = {
  list: request(GET, `${organisationUrl}/integrations`, {
    integrations: Schema.integrations,
    categories: [new Schema.Entity('categories')],
  }),
  find: request(GET, `${organisationUrl}/integrations{/id}`, {
    integration: Schema.integration,
  }),
  update: request(PUT, `${organisationUrl}/integrations{/id}`, {
    integration: Schema.integration,
  }),
};

export const payments = {
  new: request(GET, `/payments{/integration}/new{?params*}`),
  create: request(POST, `/payments{/integration}`),
};

export const reporting = {
  overview: request(GET, `${organisationUrl}/reporting/overview`),
  collectionTrend: request(
    GET,
    `${organisationUrl}/reporting/collection_trends`
  ),
  agedReceivables: request(
    GET,
    `${organisationUrl}/reporting/aged_receivables`
  ),
  workflows: request(GET, `${organisationUrl}/reporting/workflows`),
};
