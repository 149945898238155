import invariant from 'invariant';
import {
  capitalize,
  conformsTo,
  get,
  isNumber,
  isString,
  omit,
  sortBy,
} from 'lodash/fp';
import { EventTypes } from 'redux-segment';

import { identify } from '~/utils/instrumentation';

import createApiAction from './createApiAction';
import * as api from './routes';

const trackAction = (eventName, actionType) => () => ({
  analytics: {
    eventType: EventTypes.track,
    eventPayload: {
      event: eventName,
      properties: {
        actionType,
      },
    },
  },
});

const ensurePayloadIsShape = (shape) => {
  const conformsToShape = conformsTo(shape);
  return (payload) => {
    invariant(
      conformsToShape(payload),
      `Expected a ${Object.keys(shape)} shaped payload, got: ${Object.keys(
        payload
      )}`
    );

    return payload;
  };
};

const trackMoveTo = (objectType) => (_, originalPayload) => ({
  analytics: {
    eventType: EventTypes.track,
    eventPayload: {
      event: `${capitalize(objectType)} moved`,
      properties: {
        collector: originalPayload.collectorType,
        ids: originalPayload[`${objectType}Ids`],
      },
    },
  },
});

// ------------------------------------
// Activity
// ------------------------------------
export const contactActivity = createApiAction('CONTACT_ACTIVITY', {
  api: api.activities.contact,
});

export const activity = createApiAction('ACTIVITY', {
  api: api.activities.list,
});

// ------------------------------------
// Auth
// ------------------------------------

export const login = createApiAction('LOGIN', {
  api: api.auth.login,
  payloadCreator: (payload) => ({ user: payload }),
  metaCreator: () => ({ headers: { Authorization: null }, ignoreAuth: true }),
});

export const logout = createApiAction('LOGOUT', {
  api: api.auth.logout,
});

export const openIdConnect = createApiAction('OPEN_ID_CONNECT', {
  api: api.auth.openId,
  metaCreator: () => ({ ignoreAuth: true }),
});

// ------------------------------------
// Access Tokens
// ------------------------------------
export const accessTokensList = createApiAction('ACCESS_TOKENS_LIST', {
  api: api.accessTokens.list,
});

export const accessTokenCreate = createApiAction('ACCESS_TOKEN_CREATE', {
  api: api.accessTokens.create,
});

export const accessTokenDelete = createApiAction('ACCESS_TOKEN_DELETE', {
  api: api.accessTokens.delete,
});

// ------------------------------------
// Call List
// ------------------------------------

export const callContactsList = createApiAction('CALL_CONTACTS_LIST', {
  api: api.contacts.list,
  payloadCreator: (payload) => ({
    params: {
      sort: 'amount_due_desc',
      ...payload,
    },
  }),
});

export const callCollectionsList = createApiAction('CALL_COLLECTIONS_LIST', {
  api: api.collections.list,
  payloadCreator: (payload) => ({
    params: {
      sort: 'due_date_desc',
      // collectorType: 'credit_control',
      nextActionType: 'phone',
      ...payload,
    },
  }),
});

export const callCollectionsStats = createApiAction('COLLECTION_STATS', {
  api: api.collections.stats,
  payloadCreator: ({ collection, ...params }) => ({
    params: {
      ...params,
      ...collection,
      nextActionType: 'phone',
    },
  }),
});

// ------------------------------------
// Collect
// ------------------------------------

export const collectCollectionsList = createApiAction(
  'COLLECT_COLLECTIONS_LIST',
  {
    api: api.collections.list,
    payloadCreator: (params) => ({
      params,
    }),
  }
);

// ------------------------------------
// Collection actions
// ------------------------------------

export const actionApprove = createApiAction('ACTION_APPROVE', {
  api: api.collections.approve,
  successMetaCreator: trackAction('Collection Action', 'approve'),
});

export const actionCancel = createApiAction('ACTION_CANCEL', {
  api: api.collections.cancel,
  successMetaCreator: trackAction('Collection Action', 'cancel'),
});

export const actionPause = createApiAction('ACTION_PAUSE', {
  api: api.collections.pause,
  successMetaCreator: trackAction('Collection Action', 'pause'),
});

export const actionUnpause = createApiAction('ACTION_UNPAUSE', {
  api: api.collections.unpause,
  successMetaCreator: trackAction('Collection Action', 'unpause'),
});

export const actionAgentEvent = createApiAction('ACTION_AGENT_EVENT', {
  api: api.collections.agentEvent,
  successMetaCreator: trackAction('Collection Action', 'agentEvent'),
});

export const actionWaitOnClient = createApiAction('ACTION_WAIT_ON_CLIENT', {
  api: api.collections.updateAll,
  payloadCreator: (props) => ({ status: 'waiting', ...props }),
  successMetaCreator: trackAction('Collection Action', 'waitOnClient'),
});

export const actionResumeCollecting = createApiAction(
  'ACTION_RESUME_COLLECTING',
  {
    api: api.collections.resume,
    successMetaCreator: trackAction('Collection Action', 'resumeCollecting'),
  }
);

export const actionSkipToNextStep = createApiAction(
  'ACTION_SKIP_TO_NEXT_STEP',
  {
    api: api.collections.skipToNextStep,
    successMetaCreator: trackAction('Collection Action', 'skipToNextStep'),
  }
);

export const actionBulkSkipToNextStep = createApiAction(
  'ACTION_BULK_SKIP_TO_NEXT_STEP',
  {
    api: api.collections.bulkSkipToNextStep,
    successMetaCreator: trackAction('Collection Action', 'bulkSkipToNextStep'),
  }
);

// ------------------------------------
// Collections
// ------------------------------------
export const collectionsList = createApiAction('COLLECTIONS_LIST', {
  api: api.collections.list,
  payloadCreator: (params) => ({
    params,
  }),
});

export const collectionFind = createApiAction('COLLECTION_FIND', {
  api: api.collections.find,
});

export const collectionsUpdate = createApiAction('COLLECTIONS_UPDATE', {
  api: api.collections.updateAll,
});

export const collectionsMove = createApiAction('COLLECTIONS_MOVE', {
  api: api.collections.moveTo,
  successMetaCreator: trackMoveTo('collection'),
});

export const collectionStats = createApiAction('COLLECTION_STATS', {
  api: api.collections.stats,
  payloadCreator: ({
    collection: { nextActionType, ...collection } = {},
    nextActionType: _nextActionType,
    ...params
  }) => ({
    params: {
      ...params,
      ...collection,
    },
  }),
});

// ------------------------------------
// Collectors
// ------------------------------------
const trackCollectorAction = (editType) => (_, originalPayload) => ({
  analytics: {
    eventType: EventTypes.track,
    eventPayload: {
      event: 'Collector Edited',
      properties: {
        editType,
        name: originalPayload.name,
      },
    },
  },
});

const trackDefaultChanged = () => (payload) => ({
  analytics: {
    eventType: EventTypes.track,
    eventPayload: {
      event: 'Default Collector Changed',
      properties: {
        name: payload.name,
      },
    },
  },
});

const sortRemindersInPayload = (collector) => ({
  ...collector,
  ...(collector.reminders
    ? { reminders: sortBy('sequence', collector.reminders) }
    : {}),
});

export const collectorsList = createApiAction('COLLECTORS_LIST', {
  api: api.collectors.list,
});

export const collectorFind = createApiAction('COLLECTOR_FIND', {
  api: api.collectors.find,
});

export const collectorEdit = createApiAction('COLLECTOR_EDIT', {
  api: api.collectors.find,
});

export const collectorAdd = createApiAction('COLLECTOR_ADD', {
  api: api.collectors.add,
});

export const collectorSave = createApiAction('COLLECTOR_SAVE', {
  api: api.collectors.update,
  payloadCreator: sortRemindersInPayload,
  successMetaCreator: trackCollectorAction('Updated'),
});

export const collectorCreate = createApiAction('COLLECTOR_CREATE', {
  api: api.collectors.create,
  payloadCreator: sortRemindersInPayload,
  successMetaCreator: trackCollectorAction('Created'),
});

export const collectorMakeDefault = createApiAction('COLLECTOR_MAKE_DEFAULT', {
  api: api.collectors.update,
  payloadCreator: ({ id, organisationId }) => ({
    organisationId,
    id,
    isDefault: true,
  }),
  successMetaCreator: trackDefaultChanged(),
});

export const collectorClone = createApiAction('COLLECTOR_CLONE', {
  api: api.collectors.clone,
  successMetaCreator: trackCollectorAction('Copied'),
});

export const collectorDelete = createApiAction('COLLECTOR_DELETE', {
  api: api.collectors.delete,
  successMetaCreator: trackCollectorAction('Delete'),
});

// ------------------------------------
// Contacts
// ------------------------------------
export const contactFind = createApiAction('CONTACT_FIND', {
  api: api.contacts.find,
});

export const contactsList = createApiAction('CONTACTS_LIST', {
  api: api.contacts.list,
  payloadCreator: (params) => ({
    params,
  }),
});

export const contactUpdate = createApiAction('CONTACT_UPDATE', {
  api: api.contacts.update,
});

export const contactsMove = createApiAction('CONTACTS_MOVE', {
  api: api.contacts.moveTo,
  successMetaCreator: trackMoveTo('contact'),
});

export const contactSample = createApiAction('CONTACT_SAMPLE', {
  api: api.contacts.sample,
});

// ------------------------------------
// Current User
// ------------------------------------
export const currentUserFind = createApiAction('CURRENT_USER_FIND', {
  api: api.me.find,
  successMetaCreator: identifyUserOnSuccess,
});

export const currentUserUpdate = createApiAction('CURRENT_USER_UPDATE', {
  api: api.me.update,
  successMetaCreator: identifyUserOnSuccess,
});

function identifyUserOnSuccess({
  user: { uuid, email, fullname, createdAt, intercomUserHash },
}) {
  // WARNING: side-effect
  identify(uuid, {
    name: fullname,
    email: email,
  });

  return {
    analytics: {
      eventType: EventTypes.identify,
      eventPayload: {
        userId: uuid,
        traits: {
          email,
          name: fullname,
          createdAt,
        },
        options: {
          integrations: {
            Intercom: { user_hash: intercomUserHash },
          },
        },
      },
    },
  };
}

// ------------------------------------
// Current User Settings
// ------------------------------------

export const currentUserSettingsFind = createApiAction(
  'CURRENT_USER_SETTINGS_FIND',
  {
    api: api.userSettings.find,
  }
);

export const currentUserSettingsUpdate = createApiAction(
  'CURRENT_USER_SETTINGS_UPDATE',
  {
    api: api.userSettings.update,
  }
);

// ------------------------------------
// Jobs
// ------------------------------------

export const jobFind = createApiAction('JOB_FIND', {
  api: api.jobs.find,
});

// ------------------------------------
// lastImport
// ------------------------------------

export const lastImport = createApiAction('LAST_IMPORT', {
  api: api.imports.last,
});

// ------------------------------------
// Integrations
// ------------------------------------
export const integrationsList = createApiAction('INTEGRATIONS_LIST', {
  api: api.integrations.list,
});

export const integrationUpdate = createApiAction('INTEGRATION_UPDATE', {
  api: api.integrations.update,
});

export const integrationFind = createApiAction('INTEGRATION_FIND', {
  api: api.integrations.find,
});

// ------------------------------------
// Invoice
// ------------------------------------

export const invoicesUpdate = createApiAction('INVOICES_UPDATE', {
  api: api.invoices.updateAll,
});

export const invoiceResend = createApiAction('INVOICE_RESEND', {
  api: api.invoices.resend,
});

// ------------------------------------
// Notes
// ------------------------------------
const noteShape = {
  organisationId: isNumber,
  noteableType: isString,
  noteableId: isNumber,
  message: isString,
};

export const notesList = createApiAction('NOTES_LIST', {
  api: api.notes.list,
  payloadCreator: (payload) => ({
    params: payload,
  }),
});

export const noteCreate = createApiAction('NOTE_CREATE', {
  api: api.notes.create,
  payloadCreator: ensurePayloadIsShape(noteShape),
});

export const noteUpdate = createApiAction('NOTE_UPDATE', {
  api: api.notes.update,
});

export const noteDelete = createApiAction('NOTE_DELETE', {
  api: api.notes.delete,
});

export const noteResolve = createApiAction('NOTE_RESOLVE', {
  api: api.notes.resolve,
});

// ------------------------------------
// List Filters
// ------------------------------------
export const listFiltersList = createApiAction('LIST_FILTERS_LIST', {
  api: api.listFilters.list,
});

export const listFilterCreate = createApiAction('LIST_FILTER_CREATE', {
  api: api.listFilters.create,
});

export const listFilterUpdate = createApiAction('LIST_FILTER_UPDATE', {
  api: api.listFilters.update,
});

export const listFilterDelete = createApiAction('LIST_FILTER_DELETE', {
  api: api.listFilters.delete,
});

// ------------------------------------
// Organisations
// ------------------------------------
const removeAssociations = omit([
  'users',
  'contacts',
  'collectors',
  'collections',
]);

const trackCreateAction =
  () =>
  ({ entities, result }) => ({
    analytics: {
      eventType: EventTypes.track,
      eventPayload: {
        event: 'Organisation Added',
        properties: {
          provider: entities.organisations[result.organisation].provider,
        },
      },
    },
  });

export const organisationsList = createApiAction('ORGANISATIONS_LIST', {
  api: api.organisations.list,
});

export const organisationFind = createApiAction('ORGANISATION_FIND', {
  api: api.organisations.find,
});

export const organisationCreate = createApiAction('ORGANISATION_CREATE', {
  api: api.organisations.create,
  successMetaCreator: trackCreateAction(),
});

export const organisationUpdate = createApiAction('ORGANISATION_UPDATE', {
  api: api.organisations.update,
  payloadCreator: removeAssociations,
});

export const organisationChangePlan = createApiAction(
  'ORGANISATION_CHANGE_PLAN',
  {
    api: api.organisations.update,
    payloadCreator: removeAssociations,
  }
);

export const organisationDelete = createApiAction('ORGANISATION_DELETE', {
  api: api.organisations.delete,
});

// ------------------------------------
// OAuth
// ------------------------------------
export const oauthBegin = createApiAction('OAUTH_BEGIN', {
  api: api.oauth.begin,
});

export const oauthFinish = createApiAction('OAUTH_FINISH', {
  api: api.oauth.finish,
});

export const oauthReauth = createApiAction('OAUTH_FINISH', {
  api: api.oauth.reauth,
});

export const oauthDisconnect = createApiAction('OAUTH_DISCONNECT', {
  api: api.oauth.disconnect,
});

// ------------------------------------
// Public
// ------------------------------------
export const publicContactFind = createApiAction('PUBLIC_CONTACT_FIND', {
  api: api.publicContact.find,
});

export const publicInvoiceFind = createApiAction('PUBLIC_INVOICE_FIND', {
  api: api.publicInvoice.find,
});

// ------------------------------------
// Phone Call
// ------------------------------------
export const phoneCallNew = createApiAction('PHONE_CALL_NEW', {
  api: api.phoneCalls.new,
});

export const phoneCallCreate = createApiAction('PHONE_CALL_CREATE', {
  api: api.phoneCalls.create,
});

// ------------------------------------
// Remind List
// ------------------------------------

export const remindContactsList = createApiAction('REMIND_CONTACTS_LIST', {
  api: api.contacts.list,
  payloadCreator: (payload) => ({
    params: {
      status: 'active',
      sort: 'amount_due_desc',
      ...payload,
    },
  }),
});

export const remindContactsSearch = createApiAction('REMIND_CONTACTS_SEARCH', {
  api: api.contacts.search,
  metaCreator: (meta) => ({
    debounce: 500,
    ...meta,
  }),
});

// TODO: remove once we no longer show individual invoices
export const remindInvoicesList = createApiAction('REMIND_INVOICES_LIST', {
  api: api.collections.list,
  payloadCreator: (payload) => ({
    params: {
      sort: 'amount_due_desc',
      collectorType: ['regular', 'smart'],
      ...payload,
    },
  }),
});

export const remindCollectionsList = createApiAction(
  'REMIND_COLLECTIONS_LIST',
  {
    api: api.collections.list,
    payloadCreator: (payload) => ({
      params: {
        sort: 'due_date_desc',
        collectorType: ['regular', 'smart'],
        perPage: 1000,
        ...payload,
      },
    }),
  }
);

// ------------------------------------
// Analytics
// ------------------------------------
const trackReassignment = (objectType) => (_, originalPayload) => ({
  analytics: {
    eventType: EventTypes.track,
    eventPayload: {
      event: 'Collector Assigned',
      properties: {
        objectType,
        collector: get(`${objectType}s[0].collectorId`, originalPayload),
      },
    },
  },
});

// ------------------------------------
// Actions
// ------------------------------------
export const contactsReassign = createApiAction('CONTACTS_REASSIGN', {
  api: api.contacts.reassign,
  successMetaCreator: trackReassignment('contact'),
});

export const bulkContactsReassign = createApiAction('BULK_CONTACTS_REASSIGN', {
  api: api.contacts.bulkReassign,
  successMetaCreator: trackReassignment('contact'),
});

export const collectionsReassign = createApiAction('COLLECTIONS_REASSIGN', {
  api: api.collections.reassign,
  successMetaCreator: trackReassignment('collection'),
});

export const bulkCollectionsReassign = createApiAction(
  'BULK_COLLECTIONS_REASSIGN',
  {
    api: api.collections.bulkAssign,
    successMetaCreator: trackReassignment('collection'),
  }
);

export const contactsReassignToRemind = createApiAction(
  'CONTACTS_REASSIGN_TO_REMIND',
  {
    api: api.contacts.reassign,
    successMetaCreator: trackReassignment('contact'),
  }
);

export const collectionsReassignToRemind = createApiAction(
  'COLLECTIONS_REASSIGN_TO_REMIND',
  {
    api: api.collections.reassign,
    successMetaCreator: trackReassignment('collection'),
  }
);

export const contactsReassignToCall = createApiAction(
  'CONTACTS_REASSIGN_TO_CALL',
  {
    api: api.contacts.reassign,
    successMetaCreator: trackReassignment('contact'),
  }
);

export const collectionsReassignToCall = createApiAction(
  'COLLECTIONS_REASSIGN_TO_CALL',
  {
    api: api.collections.reassign,
    successMetaCreator: trackReassignment('collection'),
  }
);

export const contactsReassignToCollect = createApiAction(
  'CONTACTS_REASSIGN_TO_COLLECT',
  {
    api: api.contacts.reassign,
    successMetaCreator: trackReassignment('contact'),
  }
);

export const collectionsReassignToCollect = createApiAction(
  'COLLECTIONS_REASSIGN_TO_COLLECT',
  {
    api: api.collections.reassign,
    successMetaCreator: trackReassignment('collection'),
  }
);

// ------------------------------------
// Register
// ------------------------------------
export const registerConfirm = createApiAction('REGISTER_CONFIRM', {
  api: api.auth.confirm,
});

export const registerInvite = createApiAction('REGISTER_INVITE', {
  api: api.auth.acceptInvite,
});

// ------------------------------------
// Reminder
// ------------------------------------
export const reminderCopy = createApiAction('REMINDER_COPY', {
  api: api.reminders.create,
  successMetaCreator: trackAction('Reminder Edited', 'Reminder copied'),
});

export const reminderAdd = createApiAction('REMINDER_ADD', {
  api: api.reminders.add,
});

export const reminderCreate = createApiAction('REMINDER_CREATE', {
  api: api.reminders.create,
  successMetaCreator: trackAction('Reminder Edited', 'Reminder added'),
});

export const reminderDelete = createApiAction('REMINDER_DELETE', {
  api: api.reminders.delete,
  successMetaCreator: trackAction('Reminder Edited', 'Reminder deleted'),
});

export const reminderUpdate = createApiAction('REMINDER_UPDATE', {
  api: api.reminders.update,
  successMetaCreator: trackAction('Reminder Edited', 'Reminder updated'),
});

export const reminderSendSample = createApiAction('REMINDER_SEND_SAMPLE', {
  api: api.reminders.sample,
  successMetaCreator: trackAction('Reminder sample', 'Reminder sample sent'),
});

// ------------------------------------
// Reports
// ------------------------------------
export const dashboardReport = createApiAction('DASHBOARD', {
  api: api.reports.dashboard,
});

export const callReport = createApiAction('CALL_REPORT', {
  api: api.reports.call,
});

export const emailCallReport = createApiAction('EMAIL_CALL_REPORT', {
  api: api.reports.email,
});

export const reportFind = createApiAction('REPORT_FIND', {
  api: api.reports.find,
});

// ------------------------------------
// Reset Password
// ------------------------------------
export const passwordReset = createApiAction('PASSWORD_RESET', {
  api: api.auth.resetPassword,
  metaCreator: () => ({ ignoreAuth: true }),
  payloadCreator: (user) => ({ user }),
});

export const forgotPassword = createApiAction('FORGOT_PASSWORD', {
  api: api.auth.forgotPassword,
  payloadCreator: (user) => ({ user }),
});

// ------------------------------------
// Sample Reminder
// ------------------------------------
export const sampleReminderSend = createApiAction('SAMPLE_REMINDER_SEND', {
  api: api.gettingStarted.sampleReminder,
});

// ------------------------------------
// Roles
// ------------------------------------

export const roleAdd = createApiAction('ROLE_CREATE', {
  api: api.roles.create,
});

export const roleRemove = createApiAction('ROLE_DELETE', {
  api: api.roles.delete,
});

// ------------------------------------
// Scheduled
// ------------------------------------
export const scheduledList = createApiAction('SCHEDULED_LIST', {
  api: api.collections.schedule,
  payloadCreator: (payload) => ({
    params: {
      sort: 'next_action_time_asc',
      ...payload,
    },
  }),
});

// ------------------------------------
// Settings
// ------------------------------------
const trackPauseChange = () => (_, originalPayload) => ({
  analytics: {
    eventType: EventTypes.track,
    eventPayload: {
      event: 'Organisation Pause Changed',
      properties: {
        status: originalPayload.pause ? 'pause' : 'unpause',
      },
    },
  },
});

export const organisationPauseToggle = createApiAction(
  'ORGANISATION_PAUSE_TOGGLE',
  {
    api: api.organisations.pause,
    metaCreator: (payload) => ({
      api: payload.pause ? api.organisations.pause : api.organisations.unpause,
    }),
    successMetaCreator: trackPauseChange(),
  }
);

export const organisationEmailSenderUpdate = createApiAction(
  'ORGANISATION_EMAIL_SENDER_UPDATE',
  {
    api: api.organisations.update,
  }
);

export const organisationSettingsUpdate = createApiAction(
  'ORGANISATION_SETTINGS_UPDATE',
  {
    api: api.organisations.update,
    blocking: true,
    payloadCreator: ({ organisation, settings }) => ({
      id: organisation.id,
      settings: {
        ...organisation.settings,
        ...settings,
      },
    }),
  }
);

export const organisationSettingUpdate = createApiAction(
  'ORGANISATION_SETTING_UPDATE',
  {
    api: api.organisations.updateSettings,
    blocking: true,
    payloadCreator: ({ organisationId, ...settings }) => ({
      id: organisationId,
      settings,
    }),
  }
);

// ------------------------------------
// Subscription
// ------------------------------------
const trackSubscriptionAction = (eventName) => (_, originalPayload) => ({
  analytics: {
    eventType: EventTypes.track,
    eventPayload: {
      event: eventName,
      properties: omit(['organisationId'], originalPayload),
    },
  },
});

export const subscriptionFind = createApiAction('SUBSCRIPTION_FIND', {
  api: api.subscription.find,
});

export const subscriptionConfigure = createApiAction('SUBSCRIPTION_CONFIGURE', {
  api: api.subscription.update,
});

export const subscriptionUpdate = createApiAction('SUBSCRIPTION_UPDATE', {
  api: api.subscription.update,
});

export const subscriptionApplyCoupon = createApiAction(
  'SUBSCRIPTION_APPLY_COUPON',
  {
    api: api.subscription.coupons,
    successMetaCreator: trackSubscriptionAction('Apply coupon'),
  }
);

// ------------------------------------
// Timezones
// ------------------------------------
export const timezonesList = createApiAction('TIMEZONES_LIST', {
  api: api.resources.timezones,
});

// ------------------------------------
// Users
// ------------------------------------
export const usersList = createApiAction('USERS_LIST', {
  api: api.users.list,
});

export const invitePartner = createApiAction('INVITE_PARTNER', {
  api: api.users.partner,
  getRef: (payload) => payload.invitedOrgId,
});

export const userInvite = createApiAction('USER_INVITE', {
  api: api.users.invite,
});

export const userRevoke = createApiAction('USER_REVOKE', {
  api: api.users.revoke,
});

export const userInviteSupport = createApiAction('USER_INVITE', {
  api: api.users.invite,
  payloadCreator: ({ organisationId }) => ({
    organisationId,
    email: 'support.team@debtordaddy.com',
    fullname: 'Support Team',
  }),
});

export const supportInviteSelf = createApiAction('USER_INVITE', {
  api: api.me.addAccess,
  payloadCreator: ({ organisationId, user }) => ({
    id: organisationId,
    user: user,
  }),
  // Fake the payload of a regular user invite
  successPayloadCreator: (payload, { payload: { id, user } }) => ({
    organisationId: id,
    entities: {
      users: {
        [user.id]: user,
      },
    },
    result: {
      user: user.id,
    },
  }),
});

// ------------------------------------
// Reporting
// ------------------------------------
export const reportingOverview = createApiAction('REPORTING_OVERVIEW', {
  api: api.reporting.overview,
});

export const reportingCollectionTrend = createApiAction(
  'REPORTING_COLLECTION_TREND',
  {
    api: api.reporting.collectionTrend,
  }
);
export const reportingAgedReceivables = createApiAction(
  'REPORTING_AGED_RECEIVABLES',
  {
    api: api.reporting.agedReceivables,
  }
);
export const reportingTopDebtor = createApiAction('REPORTING_TOP_DEBTOR', {
  api: api.contacts.search,
  payloadCreator: ({ organisationId }) => ({
    organisationId,
    listFilter: {
      name: 'All',
      sourceType: 'Contact',
      predicateGroup: {
        operator: 'and',
        predicates: [
          {
            attribute_name: 'account_total_amount_overdue',
            value: '0',
            comparison: 'gt',
            predicateType: 'numeric',
          },
        ],
        predicate_groups: [],
      },
      s: 'account_total_amount_overdue desc',
    },
    page: 1,
    perPage: 10,
    includeCollections: true,
  }),
});
export const reportingWorkflows = createApiAction('REPORTING_WORKFLOWS', {
  api: api.reporting.workflows,
});
