import React from 'react';

import Text from '~/components/Text';
import styled from '~/lib/styled';

const Circle = ({ shift, ...props }) => (
  <circle fill="currentColor" cx={16 + shift * 32} cy="5" r="10" {...props} />
);

const LoadingIndicator = styled(Text, {
  is: 'span',
  textAlign: 'center',
  'data-testid': 'loading-indicator',
  children: <LoadingIndicatorSvg />,
  blacklist: ['loading', 'block'],
  loading: true,
})((p) => ({
  visibility: p.loading ? 'visible' : 'hidden',
  display: p.block ? 'block' : 'inline-block',
}));

export default LoadingIndicator;

function LoadingIndicatorSvg() {
  return (
    <svg
      width="38"
      height="10"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 10"
      preserveAspectRatio="xMidYMid"
      className="uil-ellipsis"
    >
      <Circle shift={0}>
        <animate
          id="anir11"
          attributeName="r"
          from="0"
          to="10"
          begin="0s;anir14.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anir12"
          attributeName="r"
          from="10"
          to="10"
          begin="anir11.end"
          dur="1.25s"
          fill="freeze"
        />
        <animate
          id="anir13"
          attributeName="r"
          from="10"
          to="0"
          begin="anir12.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anir14"
          attributeName="r"
          from="0"
          to="0"
          begin="anir13.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix11"
          attributeName="cx"
          from="16"
          to="16"
          begin="0s;anix18.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix12"
          attributeName="cx"
          from="16"
          to="16"
          begin="anix11.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix13"
          attributeName="cx"
          from="16"
          to="50"
          begin="anix12.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix14"
          attributeName="cx"
          from="50"
          to="50"
          begin="anix13.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix15"
          attributeName="cx"
          from="50"
          to="84"
          begin="anix14.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix16"
          attributeName="cx"
          from="84"
          to="84"
          begin="anix15.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix17"
          attributeName="cx"
          from="84"
          to="84"
          begin="anix16.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix18"
          attributeName="cx"
          from="84"
          to="16"
          begin="anix17.end"
          dur="0.25s"
          fill="freeze"
        />
      </Circle>
      <Circle shift={1}>
        <animate
          id="anir21"
          attributeName="r"
          from="10"
          to="10"
          begin="0s;anir25.end"
          dur="1s"
          fill="freeze"
        />
        <animate
          id="anir22"
          attributeName="r"
          from="10"
          to="0"
          begin="anir21.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anir23"
          attributeName="r"
          from="0"
          to="0"
          begin="anir22.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anir24"
          attributeName="r"
          from="0"
          to="10"
          begin="anir23.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anir25"
          attributeName="r"
          from="10"
          to="10"
          begin="anir24.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix21"
          attributeName="cx"
          from="16"
          to="50"
          begin="0s;anix28.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix22"
          attributeName="cx"
          from="50"
          to="50"
          begin="anix21.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix23"
          attributeName="cx"
          from="50"
          to="84"
          begin="anix22.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix24"
          attributeName="cx"
          from="84"
          to="84"
          begin="anix23.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix25"
          attributeName="cx"
          from="84"
          to="84"
          begin="anix24.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix26"
          attributeName="cx"
          from="84"
          to="16"
          begin="anix25.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix27"
          attributeName="cx"
          from="16"
          to="16"
          begin="anix26.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix28"
          attributeName="cx"
          from="16"
          to="16"
          begin="anix27.end"
          dur="0.25s"
          fill="freeze"
        />
      </Circle>
      <Circle shift={2}>
        <animate
          id="anir31"
          attributeName="r"
          from="10"
          to="10"
          begin="0s;anir35.end"
          dur="0.5s"
          fill="freeze"
        />
        <animate
          id="anir32"
          attributeName="r"
          from="10"
          to="0"
          begin="anir31.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anir33"
          attributeName="r"
          from="0"
          to="0"
          begin="anir32.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anir34"
          attributeName="r"
          from="0"
          to="10"
          begin="anir33.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anir35"
          attributeName="r"
          from="10"
          to="10"
          begin="anir34.end"
          dur="0.75s"
          fill="freeze"
        />
        <animate
          id="anix31"
          attributeName="cx"
          from="50"
          to="84"
          begin="0s;anix38.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix32"
          attributeName="cx"
          from="84"
          to="84"
          begin="anix31.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix33"
          attributeName="cx"
          from="84"
          to="84"
          begin="anix32.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix34"
          attributeName="cx"
          from="84"
          to="16"
          begin="anix33.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix35"
          attributeName="cx"
          from="16"
          to="16"
          begin="anix34.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix36"
          attributeName="cx"
          from="16"
          to="16"
          begin="anix35.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix37"
          attributeName="cx"
          from="16"
          to="50"
          begin="anix36.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix38"
          attributeName="cx"
          from="50"
          to="50"
          begin="anix37.end"
          dur="0.25s"
          fill="freeze"
        />
      </Circle>
      <Circle shift={3}>
        <animate
          id="anir41"
          attributeName="r"
          from="10"
          to="0"
          begin="0s;anir44.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anir42"
          attributeName="r"
          from="0"
          to="0"
          begin="anir41.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anir43"
          attributeName="r"
          from="0"
          to="10"
          begin="anir42.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anir44"
          attributeName="r"
          from="10"
          to="10"
          begin="anir43.end"
          dur="1.25s"
          fill="freeze"
        />
        <animate
          id="anix41"
          attributeName="cx"
          from="84"
          to="84"
          begin="0s;anix48.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix42"
          attributeName="cx"
          from="84"
          to="16"
          begin="anix41.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix43"
          attributeName="cx"
          from="16"
          to="16"
          begin="anix42.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix44"
          attributeName="cx"
          from="16"
          to="16"
          begin="anix43.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix45"
          attributeName="cx"
          from="16"
          to="50"
          begin="anix44.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix46"
          attributeName="cx"
          from="50"
          to="50"
          begin="anix45.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix47"
          attributeName="cx"
          from="50"
          to="84"
          begin="anix46.end"
          dur="0.25s"
          fill="freeze"
        />
        <animate
          id="anix48"
          attributeName="cx"
          from="84"
          to="84"
          begin="anix47.end"
          dur="0.25s"
          fill="freeze"
        />
      </Circle>
    </svg>
  );
}
