/**
 * Input tag
 */
import React from 'react';

import Box from '~/components/Box';
import Icon from '~/components/Icon';
import styled from '~/lib/styled';

import Input from '../TextInput';

const InputClear = styled(Icon, { blacklist: ['show'] })((props) => ({
  transform: 'rotate(45deg)',
  visibility: props.show ? 'visible' : 'hidden',
  cursor: 'pointer',
}));

const ClearableInput = ({ onClear, width = '100%', ...props }) => (
  <Box width={width}>
    <Input {...props} />
    <InputClear
      name="filled-add-3"
      ml="-1.5em"
      show={!!props.value}
      onClick={onClear}
    />
  </Box>
);

export default ClearableInput;
