import { isNumber, keyBy, map, omit, sumBy, upperFirst } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import Badge, { SplitBadge } from '~/components/Badge';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Flex from '~/components/Flex';
import { Checkbox, Input } from '~/components/Form';
import Heading from '~/components/Heading';
import Icon from '~/components/Icon';
import Img from '~/components/Img';
import { FormattedDate, FormattedNumber } from '~/components/Intl';
import { NavLink, PlainLink } from '~/components/Link';
import Pane from '~/components/Pane';
import { HtmlTable } from '~/components/Table';
import Text from '~/components/Text';

const REQUIRED_COLUMNS = 5;

const PublicClientDetail = ({
  organisation,
  invoices,
  totalAmountDue,
  daysOverdue,
  externalHash,
  baseCurrency,
  name,
  onSubmit,
}) => (
  <Box pb={3} mb={3}>
    <Pane borderBottom="default" bg="white.base" m="0 auto" maxWidth={1500}>
      <Flex justifyContent="space-between" p={3} alignItems="center">
        {organisation.logoUrl ? (
          <Img
            maxWidth={0.5}
            src={organisation.logoUrl}
            alt={organisation.name}
          />
        ) : (
          <Heading size={[500, 700]}>{organisation.name}</Heading>
        )}
      </Flex>
    </Pane>

    <Pane border="default" borderTop={0} m="0 auto" px={3} maxWidth={1500}>
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        py={3}
      >
        <Box mb={2}>
          <Heading size="300">Outstanding bills for</Heading>
          <Heading size={[500, 600]}>{name}</Heading>
        </Box>
        <Text textAlign={['left', 'right']}>
          <Heading size={[400, 600]}>
            <DecimalNumber value={totalAmountDue} />{' '}
            <small>{baseCurrency}</small>
          </Heading>
          <AccountDaysOverdue data-testid="banner" daysOverdue={daysOverdue} />
        </Text>
      </Flex>

      <Box width="100%">
        {organisation.paymentProviders?.[0] ? (
          <InvoicePaymentCalculator
            p={[0, 3]}
            invoices={invoices}
            baseCurrency={baseCurrency}
            totalAmountDue={totalAmountDue}
            externalHash={externalHash}
            paymentProvider={organisation.paymentProviders?.[0]}
            settings={organisation.settings}
            onSubmit={onSubmit}
          />
        ) : (
          <InvoicePaymentTable
            contactExternalHash={externalHash}
            invoices={invoices}
            baseCurrency={baseCurrency}
            totalAmountDue={totalAmountDue}
            settings={organisation.settings}
          />
        )}
      </Box>
    </Pane>
  </Box>
);

PublicClientDetail.propTypes = {
  organisation: PropTypes.object.isRequired,
  accountNumber: PropTypes.string,
  addresses: PropTypes.array,
  baseCurrency: PropTypes.string.isRequired,
  email: PropTypes.string,
  externalHash: PropTypes.string.isRequired,
  firstname: PropTypes.string,
  id: PropTypes.number,
  invoices: PropTypes.array,
  lastname: PropTypes.string,
  name: PropTypes.string.isRequired,
  phoneNumbers: PropTypes.array,
  primaryEmailAddress: PropTypes.string,
  primaryMobileNumber: PropTypes.string,
  primaryPhoneNumber: PropTypes.string,
  sms: PropTypes.string,
  totalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalAmountDue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalAmountPaid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PublicClientDetail;

function AccountDaysOverdue({ daysOverdue, ...props }) {
  return (
    daysOverdue > 0 && (
      <Pane
        mt={1}
        p={2}
        bg={daysOverdue > 0 && daysOverdue < 7 ? 'orange.base' : 'red.base'}
        {...props}
      >
        <Text textAlign="center" color="#fff">
          <Icon name="alert-2-1" />
          Your account is <strong>{daysOverdue}</strong> days late
        </Text>
      </Pane>
    )
  );
}

function Date(props) {
  return (
    <FormattedDate year="numeric" month="short" day="numeric" {...props} />
  );
}

function DecimalNumber(props) {
  return <FormattedNumber minimumFractionDigits={2} {...props} />;
}

function InvoicePaymentCalculator({
  externalHash,
  invoices,
  baseCurrency,
  totalAmountDue,
  onSubmit,
  paymentProvider,
  settings,
  ...containerProps
}) {
  const getInitialSelectedInvoices = () => {
    const invs = invoices.filter((i) => !(i.disputedAt || i.daysOverdue < 0));
    return keyBy('id', invs);
  };
  const [selectedInvoices, setSelectedInvoices] = useState(
    getInitialSelectedInvoices
  );
  const [addingCustomAmount, setAddingCustomAmount] = useState(false);
  const [amountToPay, setAmountToPay] = useState(() =>
    sumBy((i) => i?.amountDue || 0, Object.values(selectedInvoices))
  );
  const onSelectionChange = useCallback(
    (invs) => {
      setSelectedInvoices(invs);
      setAmountToPay(sumBy((i) => i?.amountDue || 0, Object.values(invs)));
    },
    [setAmountToPay]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      invoiceIds: Object.keys(selectedInvoices),
      amountInCents: amountToPay * 100,
      contactHash: externalHash,
      integration: paymentProvider.service,
    });
  };

  return (
    <Box {...containerProps}>
      <InvoicePaymentTable
        contactExternalHash={externalHash}
        settings={settings}
        invoices={invoices}
        baseCurrency={baseCurrency}
        totalAmountDue={totalAmountDue}
        amountToPay={amountToPay}
        selectedInvoices={selectedInvoices}
        onSelectionChange={onSelectionChange}
      />
      <Flex py={3} justifyContent="flex-end" alignItems="baseline">
        <Heading textAlign="right" flex="1 0 50%" size={[400, 600]} px={2}>
          Payment amount {baseCurrency}
        </Heading>
        {addingCustomAmount && (
          <Heading
            is={Input}
            flex="0 1 300px"
            size={600}
            aria-label="Custom amount to pay"
            placeholder="Enter amount to pay"
            value={amountToPay}
            onChange={(e) => {
              setSelectedInvoices({});
              setAmountToPay(e.target.value);
            }}
          />
        )}
        {!addingCustomAmount && (
          <DecimalNumber value={amountToPay}>
            {(formattedNumber) => (
              <Heading flex="0 1 auto" size={600} py={2}>
                {formattedNumber === 'NaN' ? amountToPay : formattedNumber}
              </Heading>
            )}
          </DecimalNumber>
        )}
        <PlainLink
          is="button"
          ml={2}
          border="none"
          title="Edit amount to pay"
          onClick={() => setAddingCustomAmount(!addingCustomAmount)}
        >
          {addingCustomAmount ? 'Done' : 'Edit'}
        </PlainLink>
      </Flex>
      <Flex pb={3} justifyContent="flex-end" alignItems="middle">
        <Button
          intent="success"
          appearance="primary"
          fontSize={2}
          disabled={amountToPay <= 0}
          onClick={handleSubmit}
          width={['100%', 'auto']}
        >
          Pay now &rarr;
        </Button>
      </Flex>
    </Box>
  );
}
function InvoicePaymentTable({
  settings,
  invoices,
  baseCurrency,
  totalAmountDue,
  amountToPay = 0,
  selectedInvoices = [],
  onSelectionChange,
  contactExternalHash,
}) {
  const selectingAll = useMemo(() => {
    const numSelected = Object.values(selectedInvoices).length;

    return numSelected > 0 && numSelected === invoices.length;
  }, [invoices, selectedInvoices]);

  const handleChange = useCallback(
    (id, checked) => {
      if (!onSelectionChange) return;

      const newSelectedInvoices = checked
        ? {
            ...selectedInvoices,
            [id]: invoices.find((i) => i.id === id),
          }
        : omit(id, selectedInvoices);

      onSelectionChange(newSelectedInvoices);
    },
    [onSelectionChange, selectedInvoices, invoices]
  );

  const showCol = (colName) =>
    !!settings[`accountSummaryShow${upperFirst(colName)}`];

  const columnCount =
    REQUIRED_COLUMNS +
    showCol('reference') +
    showCol('dueDate') +
    showCol('daysOverdue');

  function renderRow({
    id,
    invoiceNumber,
    reference,
    date,
    dueDate,
    daysOverdue,
    total,
    amountDue,
    amountDueLabel,
    expectedPaymentDate,
    disputedAt,
    externalHash,
  }) {
    const externalStatus = disputedAt ? (
      <Badge color="red" appearance="solid">
        Disputed
      </Badge>
    ) : expectedPaymentDate && showCol('expectedPaymentDate') ? (
      <SplitBadge>
        <Badge color="green" appearance="solid">
          Expected
        </Badge>
        <Badge color="green">
          <Date value={expectedPaymentDate} />
        </Badge>
      </SplitBadge>
    ) : null;

    return (
      <HtmlTable.Row
        key={id}
        onClick={() => {
          handleChange(id, !selectedInvoices[id]);
        }}
        // eslint-disable-next-line no-sparse-arrays
        height={[, 32]}
        bg={selectedInvoices[id] ? 'green.light' : 'transparent'}
        display={['flex', 'table-row']}
        mb={[1, 0]}
        flex="1 1 auto"
        flexWrap="wrap"
      >
        <HtmlTable.Cell
          px={2}
          width={[0.1, 0]}
          display={['block', 'table-cell']}
          borderBottom={['none', 'default']}
          order={1}
        >
          {onSelectionChange && invoiceNumber && (
            <Checkbox
              checked={!!selectedInvoices[id]}
              onChange={(e) => {
                handleChange(id, e.target.checked);
              }}
            />
          )}
        </HtmlTable.Cell>
        <HtmlTable.Cell
          width={[0.6, 'auto']}
          display={['block', 'table-cell']}
          borderBottom={['none', 'default']}
          fontWeight={[6, 0]}
          order={2}
        >
          {externalHash ? (
            <NavLink
              to={`/public/client/${contactExternalHash}/invoices/${externalHash}`}
            >
              {invoiceNumber}
            </NavLink>
          ) : (
            invoiceNumber
          )}{' '}
          {externalStatus && <Box py={1}>{externalStatus}</Box>}
        </HtmlTable.Cell>
        {showCol('reference') && (
          <HtmlTable.Cell
            display={[reference ? 'block' : 'none', 'table-cell']}
            order={5}
            width={['100%', 'auto']}
            flex="1"
            opacity={[0.8, 1]}
          >
            <Box display={['inline', 'none']}>Ref: </Box>
            {reference || '–'}
          </HtmlTable.Cell>
        )}
        <HtmlTable.Cell display={['none', 'table-cell']} order={6}>
          {date && <Date value={date} />}
        </HtmlTable.Cell>
        {showCol('dueDate') && (
          <HtmlTable.Cell
            px={2}
            display={['block', 'table-cell']}
            order={7}
            flex="1"
            opacity={[0.8, 1]}
          >
            {dueDate && (
              <>
                <Box display={['inline', 'none']}>Due: </Box>
                <Date value={dueDate} />
              </>
            )}
          </HtmlTable.Cell>
        )}
        {showCol('daysOverdue') && (
          <HtmlTable.Cell
            display={['block', 'table-cell']}
            order={8}
            flex="1"
            opacity={[0.8, 1]}
            color={daysOverdue > 0 && 'red.base'}
            fontWeight={daysOverdue > 0 && '600'}
          >
            {isNumber(daysOverdue) ? (
              daysOverdue < 0 ? (
                `${Math.abs(daysOverdue)} days from now`
              ) : (
                <>
                  {daysOverdue} days{' '}
                  <Box display={['inline', 'none']}>overdue</Box>
                </>
              )
            ) : null}
          </HtmlTable.Cell>
        )}
        <HtmlTable.Cell
          textAlign="right"
          display={['none', 'table-cell']}
          order={9}
        >
          {total && <DecimalNumber value={total} />}
        </HtmlTable.Cell>
        <HtmlTable.Cell
          textAlign="right"
          width={[0.3, 'auto']}
          display={['block', 'table-cell']}
          borderBottom={['none', 'default']}
          fontWeight={[6, 0]}
          order={3}
        >
          {amountDueLabel}
          <DecimalNumber value={amountDue} />
        </HtmlTable.Cell>
      </HtmlTable.Row>
    );
  }

  return (
    <HtmlTable display={['flex', 'table']} flexDirection={'column'}>
      <HtmlTable.Header display={['flex', 'table-row-group']}>
        <HtmlTable.Row
          display={['flex', 'table-row']}
          flex="1 1 auto"
          alignItems="center"
        >
          <HtmlTable.HeaderCell
            width={['auto', '0']}
            className="checkbox"
            display={['block', 'table-cell']}
          >
            {onSelectionChange && (
              <Checkbox
                checked={!!selectingAll}
                onChange={(e) => {
                  onSelectionChange(
                    e.target.checked ? keyBy('id', invoices) : {}
                  );
                }}
              />
            )}
          </HtmlTable.HeaderCell>
          <HtmlTable.HeaderCell
            className="invoice-num"
            display={['block', 'table-cell']}
            flexBasis={['100%', '']}
          >
            Invoice #
          </HtmlTable.HeaderCell>
          {showCol('reference') && (
            <HtmlTable.HeaderCell
              className="ref"
              display={['none', 'table-cell']}
            >
              Ref
            </HtmlTable.HeaderCell>
          )}
          <HtmlTable.HeaderCell
            className="date"
            display={['none', 'table-cell']}
          >
            Date
          </HtmlTable.HeaderCell>
          {showCol('dueDate') && (
            <HtmlTable.HeaderCell
              className="due-date"
              display={['none', 'table-cell']}
            >
              Due Date
            </HtmlTable.HeaderCell>
          )}
          {showCol('daysOverdue') && (
            <HtmlTable.HeaderCell
              className="days-overdue"
              display={['none', 'table-cell']}
            >
              Days overdue
            </HtmlTable.HeaderCell>
          )}
          <HtmlTable.HeaderCell
            textAlign="right"
            className="total"
            display={['none', 'table-cell']}
          >
            Total ({baseCurrency})
          </HtmlTable.HeaderCell>
          <HtmlTable.HeaderCell
            textAlign="right"
            className="amount-due"
            display={['block', 'table-cell']}
          >
            Amount due ({baseCurrency})
          </HtmlTable.HeaderCell>
        </HtmlTable.Row>
      </HtmlTable.Header>
      <HtmlTable.Body
        display={['flex', 'table-row-group']}
        flexDirection="column"
      >
        {map(renderRow, invoices)}
      </HtmlTable.Body>
      <HtmlTable.Footer>
        <HtmlTable.Row
          display={['flex', 'table-row']}
          flex="1 1 auto"
          alignItems="center"
          justifyContent="flex-end"
        >
          <HtmlTable.Cell
            colSpan={columnCount - 1}
            textAlign="right"
            fontWeight="600"
            display={['block', 'table-cell']}
          >
            Total {baseCurrency}
          </HtmlTable.Cell>
          <HtmlTable.Cell textAlign="right" display={['block', 'table-cell']}>
            <DecimalNumber value={totalAmountDue} />
          </HtmlTable.Cell>
        </HtmlTable.Row>
      </HtmlTable.Footer>
    </HtmlTable>
  );
}
