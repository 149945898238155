import PropTypes from 'prop-types';
import React from 'react';

import {
  Header,
  HeaderIcon as Icon,
  InnerWrapper,
  Section,
  Title,
} from '~/components/Layouts/Centered';
import A from '~/components/Link/External';
import Paragraph from '~/components/Paragraph';

export default class ErrorMessage extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    error: PropTypes.object,
    message: PropTypes.node,
    intent: PropTypes.oneOf(['danger', 'warning']),
  };

  static defaultProps = {
    intent: 'danger',
    error: {},
    message: null,
    title: 'Unknown Error',
  };

  render() {
    const { title, error, message, intent } = this.props;

    return (
      <Section my={['auto', '10vh']}>
        <Header>
          {intent === 'danger' && <Icon error name="filled-cross" size="6x" />}
          {intent === 'warning' && (
            <Icon color={'neutral.N5'} name="alert-2" size="6x" />
          )}
          <Title>{title || 'Unknown Error'}</Title>
        </Header>

        {message ? (
          <InnerWrapper>
            <Paragraph is="div">{message}</Paragraph>
          </InnerWrapper>
        ) : (
          <InnerWrapper>
            <Paragraph>
              Terribly sorry. Looks like something went wrong and we&rsquo;re
              not quite sure what... Not to bother, our{' '}
              <strong>engineers have been notified</strong> and will be looking
              into it shortly.
            </Paragraph>
            <Paragraph>
              <A href="/">Go back somewhere safe</A>
            </Paragraph>
            <Paragraph fontSize={0}>
              {error.code} {error.message}
            </Paragraph>
          </InnerWrapper>
        )}
      </Section>
    );
  }
}
