import PropTypes from 'prop-types';
import React from 'react';

import Icon from '~/components/Icon';
import LoadingIndicator from '~/components/LoadingIndicator';
import Text from '~/components/Text';
import styled, {
  alignItems,
  borderColor,
  borderRadius,
  borders,
  buttonStyle,
  color,
  intent,
  justifyContent,
  size,
  themed,
} from '~/lib/styled';

const buttonIntent = intent('buttons');

export const StyledButton = styled(Text, {
  is: 'button',
  fontSize: 0,
  fontWeight: 500,
  display: 'inline-block',
  textAlign: 'center',
  lineHeight: 'copy',
  border: 0,
  borderRadius: 2,
  px: 3,
  py: '7px', // means height will be 34px
  intent: 'none',
})(
  themed({
    appearance: 'none',
    WebkitFontSmoothing: 'antialiased',
    transition: 'box-shadow 80ms ease-in-out',
    verticalAlign: 'middle',
    cursor: 'pointer',
    outline: 'none',
    // Fix SVG capturing events in IconButton
    '& svg': {
      pointerEvents: 'none',
    },
    '&::-moz-focus-inner ': {
      border: 0,
    },
    ':disabled, &[data-disabled]': {
      cursor: 'not-allowed',
      opacity: 0.8,
      backgroundImage: 'none',
      backgroundColor: 'neutral.N1A',
      boxShadow: 'none',
      color: 'neutral.N7A',
    },
  }),
  buttonStyle,
  alignItems,
  justifyContent,
  borders,
  borderColor,
  borderRadius,
  buttonIntent,
  size,
  color // include color after intent so consumers can customize
);

const Button = React.forwardRef(function Button(props, ref) {
  const {
    children,
    isLoading,
    iconBefore,
    iconAfter,
    iconSize = 'md',
    ...rest
  } = props;

  return (
    <StyledButton role="button" ref={ref} {...rest}>
      {isLoading && <LoadingIndicator />}
      {iconBefore && <Icon name={iconBefore} size={iconSize} ml={-1} mr={1} />}
      {!isLoading && children}
      {iconAfter && <Icon name={iconAfter} size={iconSize} ml={1} mr={-1} />}
    </StyledButton>
  );
});

Button.propTypes = {
  ...StyledButton.propTypes,

  // Icon before
  iconBefore: PropTypes.string,

  // Icon after
  iconAfter: PropTypes.string,

  // Icon size
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  // Is loading
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  ...StyledButton.defaultProps,
};

export default Button;
