import Color from 'color';
import { isFunction } from 'lodash/fp';
import React from 'react';

import Card from '~/components/Card';
import Flex from '~/components/Flex';
import Pane from '~/components/Pane';
import Popover from '~/components/Popover';

export const baseColorSwatches = {
  teal: '#5AC8FA',
  blue: '#007AFF',
  red: '#FF3B30',
  pink: '#FF2D55',
  orange: '#FF9500',
  yellow: '#FFCC00',
  green: '#4CD964',
  purple: '#5856D6',
};

const swatchGroups = Object.values(baseColorSwatches).map((color) => {
  // Teal is a special case, it's a bit too light when lightened
  const isTeal = color === baseColorSwatches.teal;
  return [
    Color(color).darken(0.4).hex(),
    color,
    isTeal ? Color(color).lighten(0.3).hex() : Color(color).lighten(0.4).hex(),
  ];
});

// Add some black and grays
swatchGroups.unshift(['#000000', '#888888', '#CCCCCC']);

const defaultSwatchGroups = swatchGroups;

export { defaultSwatchGroups };

// Given an array of color swatches, going from light to dark, render a
// SwatchColorPicker that allows the user to select one of the colors.
export default function SwatchColorPicker({
  swatchGroups = defaultSwatchGroups,
  selectedColor,
  onSelect,
  children,
  closeOnSelect,
  ...props
}) {
  const button = (
    <Card
      display="inline-block"
      cursor="pointer"
      mr={3}
      mb={3}
      p={1}
      elevation={1}
      {...props}
    >
      <Pane
        width="3em"
        height="3em"
        borderRadius={2}
        backgroundColor={selectedColor}
      />
    </Card>
  );
  return (
    <Popover
      content={({ close }) => (
        <Flex
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          pt={3}
          pb={2}
          pl={3}
          pr={2}
        >
          {swatchGroups.map((group) => (
            <Flex key={group[0]} flexDirection="column">
              {group.map((color) => (
                <Card
                  aria-label={color}
                  role="button"
                  key={color}
                  onClick={() => {
                    onSelect(color);
                    closeOnSelect && close();
                  }}
                  border="default"
                  mb={2}
                  mr={2}
                  backgroundColor={color}
                  width={1}
                  height={1}
                  css={
                    color === selectedColor && {
                      boxShadow: `0 0 0 3px ${Color(color).alpha(0.4)}`,
                    }
                  }
                />
              ))}
            </Flex>
          ))}
        </Flex>
      )}
    >
      {children
        ? isFunction(children)
          ? children(selectedColor)
          : children
        : button}
    </Popover>
  );
}
